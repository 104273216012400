import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import routes from 'src/routes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MAIN_TOKEN_KEY } from './constants';
import { get } from './utils/storage';

const App = () => {
  const routing = useRoutes(routes);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = get(MAIN_TOKEN_KEY);
    if (!token) navigate('/login');
    else navigate(location.pathname);
  }, []);

  return (
    <>
      <GlobalStyles />
      {routing}
    </>
  );
};

export default App;
