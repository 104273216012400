import { SET_JOBS_LIST, SET_JOB_INFO } from './constant';
import { api } from '../../service';

/**
 * Get jobs list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getJobsList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/order');

    if (data) {
      // Dispatch User Info.
      dispatch({ type: SET_JOBS_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get job by ID.
 *
 * @param {string} jobID
 * @returns
 */
export const getJobByID = (jobID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/v2/order/${jobID}`);
    if (data) {
      dispatch({ type: SET_JOB_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update job by ID.
 *
 * @param {object} values
 * @param {string} jobID
 * @returns
 */
export const updateJobByID = (values, jobID) => async () => {
  try {
    const { data } = await api.patch(`/order/${jobID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove job by ID.
 *
 * @param {object} values
 * @param {string} jobID
 * @returns
 */
export const removeJobByID = (jobID) => async () => {
  try {
    const { data } = await api.delete(`/order/${jobID}`);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

export const killJobById = (jobID) => async () => {
  try {
    const { data } = await api.post(`/kill_order/${jobID}`);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

export const killJobWithFeeById = (jobID) => async () => {
  try {
    const { data } = await api.post(`/kill_order_with_fee/${jobID}`);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};