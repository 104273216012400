import { SET_WASHER_LIST, SET_WASHER_INFO } from './constant';
import { api } from '../../service';


export const getDocsByWasherID = (id) => async () => {
  try {
    const { data } = await api.get(`/washerdocs/${id}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  } 
}

/**
 * Get admins list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getWasherList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/washer');

    if (data) {
      // Dispatch User Info.
      dispatch({ type: SET_WASHER_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get washer by ID.
 *
 * @param {string} washerID
 * @returns
 */
export const getWasherByID = (washerID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/washer/${washerID}`);
    if (data) {
      dispatch({ type: SET_WASHER_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update washer by ID.
 *
 * @param {object} values
 * @param {string} washerID
 * @returns
 */
export const updateWasherByID = (values, washerID) => async () => {
  try {
    const { data } = await api.patch(`/washer/${washerID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add washer.
 *
 * @param {object} values
 * @returns
 */
export const createWasher = (values) => async () => {
  try {
    const { data } = await api.post('/washer', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove washer by ID.
 *
 * @param {string} washerID
 * @returns
 */
export const removeWasher = (washerID) => async () => {
  try {
    const { data } = await api.delete(`/washer/${washerID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
