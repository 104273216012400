import { SET_ADMIN_LIST, SET_ADMIN_INFO } from './constant';
import { api } from '../../service';

/**
 * Get admins list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getAdminList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/admin');
    
    if (data) {
      // Dispatch User Info.
      dispatch({ type: SET_ADMIN_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get admin by ID.
 *
 * @param {string} clientID
 * @returns
 */
export const getAdminByID = (adminID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/admin/${adminID}`);
    if (data) {
      dispatch({ type: SET_ADMIN_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update client by ID.
 *
 * @param {object} values
 * @param {string} clientID
 * @returns
 */
export const updateAdminByID = (values, adminID) => async () => {
  try {
    const { data } = await api.patch(`/admin/${adminID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add client.
 *
 * @param {object} values
 * @returns
 */
export const createAdmin = (values) => async () => {
  try {
    const { data } = await api.post('/admin', values);
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.error };
    throw new Error(message);
  }
};

/**
 * Remove client by ID.
 *
 * @param {string} clientID
 * @returns
 */
export const removeAdmin = (adminID) => async () => {
  try {
    const { data } = await api.delete(`/admin/${adminID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};


export const resetPasswordAdmin = (values) => async () => {
  try {
    const { data } = await api.post('/resetPassword', values);

    return data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};