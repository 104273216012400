import { SET_GENERAL_SETTING, SET_SMS_CONFIGURATION } from './constant';
import { api } from '../../service';

/**
 * Get general setting.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getGeneralSetting = () => async (dispatch) => {
  try {
    const { data } = await api.get('/settings');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_GENERAL_SETTING, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

export const getTranslations = () => async (dispatch) => {
  try {
    const { data } = await api.get('/translations_backoffice');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_GENERAL_SETTING, payload: data });
    }
    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update general setting.
 *
 * @param {object} values
 * @param {string} serviceID
 * @returns
 */
export const updateGeneralSettings = (values) => async () => {
  try {
    const { data } = await api.patch(`/settings`, values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

export const updateTranslations = (values) => async () => {
  try {
    const { data } = await api.patch(`/translations_backoffice`, values);

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update general service setting.
 *
 * @param {object} values
 * @param {string} serviceID
 * @returns
 */
export const updateGeneralServiceSettings = (values) => async () => {
  try {
    const { data } = await api.patch(`/service_settings`, values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get sms configuration.
 *
 * @returns
 */
export const getSmsConfiguration = () => async (dispatch) => {
  try {
    const { data } = await api.get('/smssettings');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_SMS_CONFIGURATION, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update sms configuration.
 *
 * @param {object} values
 * @returns
 */
export const updateSmsConfiguration = (values) => async () => {
  try {
    const { data } = await api.patch('/smssettings', values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};
