import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useParams } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PaymentsListResults from 'src/components/payments/PaymentsListResults';
import PaymentsToolBar from 'src/components/payments/PaymentsToolBar';
import { api } from 'src/service';
import TransactionDetail from '../../components/settlement/TransactionDetail';

const Payments = () => {
  const [payments, setPayments] = useState();
  const [transactionDetail, setTransactionDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [pagerInfo, setPagerInfo] = useState({
    limit: 10,
    page: 1,
    totalSize: 0,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api
          .post('/payments/filter', {
            page_size: pagerInfo.limit,
            current_page: pagerInfo.page,
            search: searchStr,
            washer_id: params.washerId,
          })
          .then((res) => {
            setPayments(res.data.data.data);
            setPagerInfo({
              limit: res.data.data.page_size,
              page: res.data.data.current_page,
              totalSize: res.data.data.total_size,
            });
          });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [searchStr, pagerInfo.page, pagerInfo.limit]);

  useEffect(() => {
    setLoading2(true);
    if (!params.washerId) {
      setLoading2(false);
      return;
    }
    api.get(`/income/${params.washerId}`).then((res) => {
      const { data } = res;
      console.log(data);
      setTransactionDetail(data.data);
      setLoading2(false);
    });
  }, [params.washerId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchStr = (str) => {
    setSearchStr(str);
  };

  const handlePagerInfo = (info) => {
    setPagerInfo(info);
  };

  return (
    <>
      <Helmet>
        <title>Washer - Payments</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <PaymentsToolBar
            handleSearchStr={handleSearchStr}
            id={params.washerId}
          />
          <Box sx={{ pt: 3 }}>
            <PaymentsListResults
              payments={payments}
              handlePagerInfo={handlePagerInfo}
              pagerInfo={pagerInfo}
              loading={loading}
            />
          </Box>
          <Box sx={{ pt: 3 }}>
            <TransactionDetail
              transactionDetail={transactionDetail?.income}
              loading={loading2}
              wallet={transactionDetail?.tip}
            />
          </Box>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove it?
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Payments;
