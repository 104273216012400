import { SET_SERVICE_LIST, SET_SERVICE_INFO } from './constant';
import { api } from '../../service';

/**
 * Get services list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getServiceList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/service');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_SERVICE_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get services by washer id.
 *
 * @param {string} id 
 * @returns 
 */
export const getServicesByWasherID = (id) => async (dispatch) => {
  try {
    const { data } = await api.get(`/servicesbywasherid/${id}`);

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_SERVICE_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
}

/**
 * Update services by washer id.
 *
 * @param {array} values 
 * @param {string} id 
 * @returns 
 */
export const updateServiceByWasherID = (values, id, operationRange) => async () => {
  try {
    const serviceIDs = values.map((item) => item.id);
    const { data } = await api.post(`/washerservices`, {
      washer_id: id,
      operation_range: operationRange,
      service_id: serviceIDs,
    });

    return data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
}

/**
 * Get service by ID.
 *
 * @param {string} serviceID
 * @returns
 */
export const getServiceByID = (serviceID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/service/${serviceID}`);
    if (data) {
      dispatch({ type: SET_SERVICE_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update service by ID.
 *
 * @param {object} values
 * @param {string} serviceID
 * @returns
 */
export const updateServiceByID = (values, serviceID) => async () => {
  try {
    const { data } = await api.patch(`/service/${serviceID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add service.
 *
 * @param {object} values
 * @returns
 */
export const createService = (values) => async () => {
  try {
    const { data } = await api.post('/service', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove service by ID.
 *
 * @param {string} serviceID
 * @returns
 */
export const removeService = (serviceID) => async () => {
  try {
    const { data } = await api.delete(`/service/${serviceID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
